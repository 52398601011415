import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { submitPost } from './CommunityPage.duck';
import { ensureCurrentUser } from '../../util/data';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

import SectionHero from './SectionHero';
import Posts from './Posts';
import Sidebar, { SidebarLinks } from './Sidebar';
import PostModal from './PostModal/PostModal';
import css from './CommunityPage.module.css';

const CommunityPageComponent = props => {
  const [isPostModalOpen, setPostModalOpen] = useState(false);
  const {
    scrollingDisabled,
    currentUser,
    intl,
    history,
    location,
    onManageDisableScrolling,
    onSubmitPost,
    submitPostInProgress,
    submitPostError,
    pagePosts,
    searchPostsInProgress,
    searchPostsError,
    pagination,
    filterConfig,
  } = props;

  const categories = findOptionsForSelectFilter('community-categories', filterConfig);
  const currentSearchParams = parse(location.search);
  const postsCategory = categories.find(
    category => category.key === currentSearchParams.pub_category
  );

  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  // Function to submit a new post
  const submitPost = values => {
    const { title, description, category, image } = values;
    const updatedValues = {
      title,
      description,
      publicData: {
        category,
        type: 'community',
      },
    };

    onSubmitPost(updatedValues, image)
      .then(postId => {
        setPostModalOpen(false);
        history.push(
          createResourceLocatorString('PostPage', routeConfiguration(), { id: postId }, {})
        );
      })
      .catch(e => {
        // do nothing as we handle the error
        // with redux
        console.log(e);
      });
  };

  const schemaTitle = intl.formatMessage(
    {
      id: 'CommunityPage.schemaTitle',
    },
    { siteTitle: config.siteTitle }
  );

  return (
    <Page
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'CommunityPage',
        name: schemaTitle,
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CommunityPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero category={postsCategory} />
          <div className={css.pageWrapper}>
            <SidebarLinks
              className={css.mobileSidebarCategories}
              history={history}
              categories={categories}
              currentSearchParams={currentSearchParams}
            />
            <Posts
              currentUser={ensuredCurrentUser}
              categories={categories}
              posts={pagePosts}
              searchPostsInProgress={searchPostsInProgress}
              searchPostsError={searchPostsError}
              currentSearchParams={currentSearchParams}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
            />
            <Sidebar
              history={history}
              onOpenPostModal={() => {
                if (currentUser) {
                  setPostModalOpen(true);
                } else {
                  history.push(pathByRouteName('LoginPage', routeConfiguration(), {}));
                }
              }}
              categories={categories}
              currentSearchParams={currentSearchParams}
            />
          </div>
          <PostModal
            id="PostModal"
            isOpen={isPostModalOpen}
            onCloseModal={() => setPostModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitPost={submitPost}
            submitPostInProgress={submitPostInProgress}
            submitPostError={submitPostError}
            categories={categories}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CommunityPageComponent.defaultProps = {
  filterConfig: config.custom.filters,
  onSubmitPost: null,
  submitPostInProgress: false,
  submitPostError: null,
  pagePosts: null,
  searchPostsInProgress: false,
  searchPostsError: null,
};

const { bool, func, shape, string } = PropTypes;

CommunityPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  onSubmitPost: func.isRequired,
  submitPostInProgress: bool.isRequired,
  submitError: propTypes.error,
  searchPostsInProgress: bool.isRequired,
  searchPostsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    submitPostInProgress,
    submitPostError,
    currentPageResultIds,
    searchPostsInProgress,
    searchPostsError,
    pagination,
  } = state.CommunityPage;

  const pagePosts = getListingsById(state, currentPageResultIds);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    submitPostInProgress,
    submitPostError,
    pagePosts,
    searchPostsInProgress,
    searchPostsError,
    pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSubmitPost: (actionPayload, image) => dispatch(submitPost(actionPayload, image)),
});

const CommunityPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
)(CommunityPageComponent);

export default CommunityPage;
