import React from 'react';
import { array, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, Avatar, PaginationLinks, IconArrowHead, IconComment } from '../../components';
import moment from 'moment';

import css from './CommunityPage.module.css';

const Post = props => {
  const { post, categories, isSearchByCategory } = props;
  const { title, description, publicData, createdAt } = post.attributes;

  const postId = post.id.uuid;
  const author = post.author;
  const authorDisplayName = author.attributes.profile.displayName
    ?.replace(/ .*/, '')
    ?.toLowerCase();

  const comments = publicData?.comments;
  const commentsLength = comments ? comments?.length : 0;

  const categoryKey = publicData?.category;
  const category = categories.find(category => category.key === categoryKey);

  const userDisplayName = (
    <span className={css.highlightedText}>
      <FormattedMessage id="CommunityPage.userDisplayName" values={{ authorDisplayName }} />
    </span>
  );
  const postedAt = moment(createdAt).fromNow();
  return (
    <NamedLink className={css.post} name="PostPage" params={{ id: postId, slug: categoryKey }}>
      <div className={css.postLeftContent}>
        <Avatar className={css.postAvatar} user={author} disableProfileLink />
        <div className={css.postContent}>
          <h3 className={css.postTitle}>{title}</h3>
          <div className={css.postTime}>
            <FormattedMessage id="CommunityPage.postedBy" values={{ userDisplayName, postedAt }} />
          </div>
          <p className={css.postText}>{description}</p>
        </div>
      </div>
      <div className={css.postRightContent}>
        <div className={css.postComments}>
          <IconComment className={css.commentIcon} />
          <FormattedMessage id="CommunityPage.comments" values={{ count: commentsLength }} />
        </div>
      </div>
      {isSearchByCategory ? null : (
        <div className={css.postLabel}>
          <span className={css.postLabelText}>
            <span className={css.postLabelColor} style={{ backgroundColor: category.color }} />
            {category.label}
          </span>
        </div>
      )}
    </NamedLink>
  );
};

const Posts = props => {
  const {
    posts,
    categories,
    searchPostsInProgress,
    searchPostsError,
    currentSearchParams,
    pagination,
    searchParamsForPagination,
  } = props;

  const hasPaginationInfo = !!pagination && pagination.totalItems !== null;
  const postsAreLoaded = !searchPostsInProgress && hasPaginationInfo;
  const hasListings = postsAreLoaded && pagination.totalItems > 0;
  const postsCategory = categories.find(
    category => category.key === currentSearchParams.pub_category
  );
  const isSearchByCategory = !!postsCategory?.key;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="CommunityPage"
        pageSearchParams={searchParamsForPagination}
        pagination={pagination}
      />
    ) : null;

  const returnLink =
    postsAreLoaded && postsCategory ? (
      <NamedLink className={css.returnLink} name="CommunityPage">
        <IconArrowHead className={css.returnLinkIcon} direction="left" />
        <FormattedMessage id="CommunityPage.returnLink" />
      </NamedLink>
    ) : null;

  // Render if no posts are found
  if (postsAreLoaded && !hasListings) {
    return (
      <div>
        {returnLink}
        <div className={css.noPosts}>
          <h3 className={css.noPostsTitle}>
            <FormattedMessage id="CommunityPage.noPostsTitle" />
          </h3>
          <p className={css.noPostsText}>
            {postsCategory ? (
              <FormattedMessage
                id="CommunityPage.noPostsWithCategoryText"
                values={{ category: postsCategory.label }}
              />
            ) : (
              <FormattedMessage id="CommunityPage.noPostsText" />
            )}
          </p>
        </div>
      </div>
    );
  }

  // Render if no posts are found
  if (searchPostsError) {
    return (
      <div>
        <div className={css.searchError}>
          <h3 className={css.searchErrorTitle}>
            <FormattedMessage id="CommunityPage.searchPostTitle" />
          </h3>
          <p className={css.searchErrorText}>
            <FormattedMessage id="CommunityPage.searchPostText" />
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={css.postsWrapper}>
      {returnLink}
      {hasListings ? (
        <div className={css.posts}>
          {posts.map(p => {
            return (
              <Post
                key={p.id.uuid}
                post={p}
                categories={categories}
                isSearchByCategory={isSearchByCategory}
              />
            );
          })}
        </div>
      ) : null}
      {paginationLinks}
    </div>
  );
};

Posts.defaultProps = {
  posts: [],
  categories: [],
  searchPostsInProgress: false,
  searchPostsError: null,
  pagination: null,
};

Posts.propTypes = {
  posts: array.isRequired,
  categories: array.isRequired,
  searchPostsInProgress: bool.isRequired,
  searchPostsError: propTypes.error,
  pagination: propTypes.pagination,
};

export default Posts;
